import { Button, Tab, TabList, TabPanels, Tabs, Theme } from '@carbon/react';
import React, { useCallback, useEffect, useState } from 'react';
import { Navigation } from '../../../common';
import { CARBON_THEME, CUSTOM_THEME } from '../../wizard/constants';
import { getAllScenarios } from '../../wizard/scenarioApiCalls';
import { useFetchAllUsers } from '../../wizard/modals/shareScenario/useFetchAllUsers';
import { ErrorNotification } from '../../../common/ErrorNotification/ErrorNotification';
import { approveScenario, revokeApprovedScenario, shareMultipleScenarios } from '../adminApiCalls';
import { ApproveScenarioTab } from './ApproveScenarioTab';
import { ShareScenarioTab } from './ShareScenariosTab';
import { ApproveScenarioModal } from './ApproveScenarioModal';
import { ShareScenarioModal } from './ShareScenarioModal';
import { RevokeApprovalModal } from './RevokeApprovalModal';
import { SuccessStateModal } from './SuccessStateModal';
import { FailedScenariosList } from './FailedScenariosList';
import { PublishScenarioTab } from '../PublishScenarioTab/PublishScenarioTab';
import { ScenarioInfoModal } from '../PublishScenarioTab/ScenarioInfoModal';

import './ManageScenarios.scss';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TimeoutAlert } from '../../../common/TimeoutAlert';
import { MissingUnitsTab } from '../MissingUnits/MissingUnitsTab';

export const ManageScenarios = () => {
    const [scenarioList, setScenarioList] = useState([]);
    const [approveScenarios, setApproveScenarios] = useState([]);
    const [selectedScenarios, setSelectedScenarios] = useState([]);
    const [viewScenario, setViewScenario] = useState(null);
    const [changeCount, setChangeCount] = useState(0);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [revokeScenario, setRevokeScenario] = useState('');
    const [approveScenarioModalOpen, setApproveScenarioModalOpen] = useState(false);
    const [shareScenarioModalOpen, setShareScenarioModalOpen] = useState(false);
    const [revokeApprovalModalOpen, setRevokeApprovalModalOpen] = useState(false);
    const [sharedScenarios, setSharedScenarios] = useState({ success: [], failed: [] });
    const [failedApprovedScenarios, setFailedApprovedScenarios] = useState([]);

    const [shareSuccessState, setShareSuccessState] = useState(false);
    const [revokeSuccessState, setRevokeSuccessState] = useState({ modalOpen: false, success: false });
    const [loading, setLoading] = useState(false);

    const { userList } = useFetchAllUsers();

    const navigate = useNavigate();
    const [params] = useSearchParams();
    const unpublishedScenarioId = params.get('u_sid') ?? null;

    const fetchScenarioList = useCallback(async () => {
        const { scenarios } = await getAllScenarios();
        setScenarioList(scenarios.filter((scenario) => scenario.status !== 'UNPUBLISHED'));
    }, []);

    const handleApproveModalOpen = (selections) => {
        setApproveScenarioModalOpen(true);
        const scenarioIds = selections.map((scenario) => scenario.id);
        const selectedScenarios = scenarioList.filter((scenario) => scenarioIds.includes(scenario.id));
        setApproveScenarios(selectedScenarios);
    };

    const handleRevokeApprovalModalOpen = (scenarioId) => {
        setRevokeApprovalModalOpen(true);
        setRevokeScenario(scenarioId);
    };

    const handleApproveScenario = useCallback(async () => {
        const scenarioIds = approveScenarios.map((scenario) => scenario.id);
        const { success, failedIds } = await approveScenario(scenarioIds);
        if (failedIds.length > 0) {
            const scenarioDictionary = approveScenarios.reduce(
                (acc, curr) => ({
                    ...acc,
                    [curr.id]: curr,
                }),
                {}
            );
            const failedScenarios = failedIds.map((id) => scenarioDictionary[id]);
            setFailedApprovedScenarios(failedScenarios);
        }
        if (success) {
            fetchScenarioList();
        }
        setChangeCount((prevChangeCount) => prevChangeCount + 1);
    }, [approveScenarios, fetchScenarioList]);

    const handleShareScenarios = useCallback(async () => {
        if (loading) return;

        setLoading(true);
        const { success, data } = await shareMultipleScenarios({
            scenarioIds: selectedScenarios,
            userIds: selectedUsers,
        });

        if (success) {
            setShareSuccessState(true);
            setSharedScenarios({
                success: data.filter((scenario) => scenario.share_successful),
                failed: data.filter((scenario) => !scenario.share_successful),
            });
        }

        setLoading(false);
    }, [selectedScenarios, selectedUsers, loading]);

    const handleRevokeApproval = useCallback(async () => {
        if (loading) return;

        setLoading(true);
        const { success } = await revokeApprovedScenario({ scenarioId: revokeScenario });
        if (success) {
            setRevokeSuccessState({ modalOpen: true, success: true });
            fetchScenarioList();
        } else {
            setRevokeSuccessState({ modalOpen: true, success: false });
        }
        setLoading(false);
        setChangeCount((prevChangeCount) => prevChangeCount + 1);
    }, [revokeScenario, fetchScenarioList, loading]);

    useEffect(() => {
        fetchScenarioList();
    }, [fetchScenarioList]);

    return (
        <Theme theme={localStorage.getItem('theme') === CUSTOM_THEME.DARK ? CARBON_THEME.G90 : CARBON_THEME.WHITE}>
            <div className="wizard" style={{ minHeight: '100vh' }}>
                <Navigation />
                <div className="manage-scenarios-content">
                    <Button className="back-to-admin-btn" onClick={() => navigate('/admin')}>
                        Back to Admin
                    </Button>
                    <Tabs defaultSelectedIndex={unpublishedScenarioId !== null ? 2 : 0}>
                        <TabList aria-label="List of tabs" contained>
                            <Tab data-testid="approve-scenarios-tab">Approve Scenarios</Tab>
                            <Tab data-testid="share-approved-scenarios-tab">Share Approved Scenarios</Tab>
                            <Tab data-testid="publish-scenario-tab">Publish Scenario to Guardian UMP</Tab>
                            <Tab data-testid="missing-units-tab">Missing Units</Tab>
                        </TabList>
                        <TabPanels>
                            <ApproveScenarioTab
                                scenarios={scenarioList}
                                handleApproveModalOpen={handleApproveModalOpen}
                                handleRevokeApprovalModalOpen={handleRevokeApprovalModalOpen}
                            />
                            <ShareScenarioTab
                                approvedScenarios={scenarioList.filter(
                                    (scenario) => scenario.status === 'APPROVED' || scenario.status === 'PUBLISHED'
                                )}
                                users={userList}
                                onModalOpen={() => setShareScenarioModalOpen(true)}
                                onScenarioSelect={setSelectedScenarios}
                                onUserSelect={setSelectedUsers}
                                hasSelections={selectedScenarios.length > 0 && selectedUsers.length > 0}
                            />
                            <PublishScenarioTab
                                refetch={changeCount}
                                onReviewScenario={(scenario) => setViewScenario(scenario)}
                                highlightScenarioId={unpublishedScenarioId}
                                onChange={() => fetchScenarioList()}
                            />
                            <MissingUnitsTab />
                        </TabPanels>
                    </Tabs>
                    {approveScenarioModalOpen && (
                        <ApproveScenarioModal
                            open={approveScenarioModalOpen}
                            onClose={() => setApproveScenarioModalOpen(false)}
                            scenarios={approveScenarios}
                            onSubmit={handleApproveScenario}
                        />
                    )}
                    {shareScenarioModalOpen && (
                        <ShareScenarioModal
                            open={shareScenarioModalOpen}
                            onClose={() => setShareScenarioModalOpen(false)}
                            scenarios={scenarioList.filter((scenario) => selectedScenarios.includes(scenario.id))}
                            users={userList.filter((user) => selectedUsers.includes(`${user.id}`))}
                            onSubmit={handleShareScenarios}
                        />
                    )}
                    {revokeApprovalModalOpen && (
                        <RevokeApprovalModal
                            scenario={scenarioList.find((scenario) => scenario.id === revokeScenario)?.scenario_name}
                            open={revokeApprovalModalOpen}
                            onClose={() => setRevokeApprovalModalOpen(false)}
                            onSubmit={handleRevokeApproval}
                        />
                    )}
                    {shareSuccessState && (
                        <SuccessStateModal
                            sharedScenarios={sharedScenarios}
                            userList={userList}
                            onClose={() => {
                                setShareSuccessState(false);
                                setShareScenarioModalOpen(false);
                            }}
                        />
                    )}
                    {revokeSuccessState.modalOpen && (
                        <SuccessStateModal
                            isSuccess={revokeSuccessState.success}
                            revokeScenario={
                                scenarioList.find((scenario) => scenario.id === revokeScenario)?.scenario_name
                            }
                            onClose={() => {
                                setRevokeSuccessState({ modalOpen: false, success: false });
                                setRevokeApprovalModalOpen(false);
                            }}
                        />
                    )}
                    {viewScenario ? (
                        <ScenarioInfoModal
                            open={!!viewScenario}
                            scenario={viewScenario}
                            onClose={() => setViewScenario(null)}
                        />
                    ) : null}
                    {failedApprovedScenarios.length > 0 ? (
                        <FailedScenariosList
                            scenarios={failedApprovedScenarios}
                            onClose={() => setFailedApprovedScenarios([])}
                        />
                    ) : null}
                </div>
            </div>
            <ErrorNotification />
            <TimeoutAlert />
        </Theme>
    );
};
