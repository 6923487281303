import {
    Button,
    DataTable,
    TableContainer,
    TableToolbar,
    TableToolbarContent,
    TableToolbarSearch,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell,
    TableExpandRow,
    TableExpandedRow,
    TableExpandHeader,
    TextInput,
    Dropdown,
    Link,
} from '@carbon/react';
import { CheckmarkOutline, Edit } from '@carbon/react/icons';
import PropTypes from 'prop-types';
import { useAtom } from 'jotai';
import { Fragment, useState } from 'react';
import { isEmpty } from 'lodash';

import './IssuesTable.scss';
import { currentUserAtom } from '../../utils/PrivateRoute';
import { userListDataAtom } from '../wizard/modals/shareScenario/useFetchAllUsers';
import { updateIssue } from './issuesApiCalls';

const IssuesTableProps = {
    issues: PropTypes.arrayOf(PropTypes.object).isRequired,
    ariaLabel: PropTypes.string,
    onSetIssues: PropTypes.func,
};

const statusDropdown = ['REQUESTED', 'COMPLETED', 'IN PROGRESS', 'BLOCKED', 'REJECTED'];

const headers = [
    { header: 'Issue ID', key: 'ISSUE_ID' },
    { header: 'Major Command', key: 'CMD' },
    { header: 'Issue Type', key: 'ISSUE_TYPE' },
    { header: 'Scenario Name', key: 'SCENARIO_NAME' },
    { header: 'UMP Page', key: 'UMP_PAGE' },
    { header: 'Issue Brief', key: 'BRIEF_DESC' },
    { header: 'Reported By', key: 'REPORTED_BY' },
    { header: 'Reported Date', key: 'DATE_REPORTED' },
    { header: 'Status', key: 'STATUS' },
    { header: 'Comments', key: 'COMMENTS' },
    { header: 'Links', key: 'downloadLinks' },
    { header: 'Update Issue', key: 'UPDATE' },
    { header: 'Urgency', key: 'URGENCY' },
];

export const IssuesTable = ({ issues, ariaLabel, onSetIssues }) => {
    const [userListData] = useAtom(userListDataAtom);
    const [currentUser] = useAtom(currentUserAtom);
    const userIsAbleToEdit = currentUser?.userId === 213 || currentUser?.userId === 378;
    const [rowToEdit, setRowToEdit] = useState(null);
    const [editedValues, setEditedValues] = useState({});

    const handleOpenEdit = (row) => {
        setRowToEdit(row);
    };

    const handleSubmitEditRow = async () => {
        if (!isEmpty(editedValues)) {
            const updatedIssues = issues.map((issue) => {
                if (issue.id === rowToEdit) {
                    return {
                        ...issue,
                        STATUS: editedValues['status'] ?? issue.STATUS,
                        COMMENTS: editedValues['comments'] ?? issue.COMMENTS,
                    };
                } else {
                    return issue;
                }
            });
            const { isOk } = await updateIssue({
                body: {
                    status: editedValues['status'],
                    comments: editedValues['comments'] ?? '',
                },
                issueId: rowToEdit,
            });
            onSetIssues(updatedIssues);
        }
        setEditedValues({});
        setRowToEdit(null);
    };

    return (
        <DataTable rows={issues} headers={headers}>
            {({
                rows,
                headers,
                getHeaderProps,
                getRowProps,
                getToolbarProps,
                getBatchActionProps,
                onInputChange,
                getTableProps,
                getTableContainerProps,
                getExpandedRowProps,
            }) => {
                const batchActionProps = {
                    ...getBatchActionProps(),
                };
                return (
                    <TableContainer {...getTableContainerProps()}>
                        <div>
                            <TableToolbar {...getToolbarProps()}>
                                <TableToolbarContent aria-hidden={batchActionProps.shouldShowBatchActions}>
                                    <TableToolbarSearch
                                        tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}
                                        onChange={onInputChange}
                                    />
                                </TableToolbarContent>
                            </TableToolbar>
                        </div>
                        <Table {...getTableProps()} aria-label={ariaLabel}>
                            <TableHead>
                                <TableRow>
                                    <TableExpandHeader aria-label="expand row" />
                                    {headers.map((header) => {
                                        switch (header.key) {
                                            case 'SCENARIO_NAME':
                                            case 'UMP_PAGE':
                                            case 'COMMENTS':
                                            case 'downloadLinks':
                                                return null;
                                            case 'UPDATE':
                                                return userIsAbleToEdit ? (
                                                    <TableHeader {...getHeaderProps({ header })}>
                                                        {header.header}
                                                    </TableHeader>
                                                ) : null;
                                            default:
                                                return (
                                                    <TableHeader {...getHeaderProps({ header })}>
                                                        {header.header}
                                                    </TableHeader>
                                                );
                                        }
                                    })}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {rows.map((row, index) => (
                                    <Fragment key={row.id}>
                                        <TableExpandRow {...getRowProps({ row })}>
                                            {row.cells.map((cell) => {
                                                switch (cell.info.header) {
                                                    case 'ISSUE_ID':
                                                        return <TableCell key={cell.id}>{cell.value}</TableCell>;
                                                    case 'DATE_REPORTED':
                                                        return (
                                                            <TableCell
                                                                className="table-row-date"
                                                                key={cell.id}
                                                                style={{ width: '15%' }}
                                                            >
                                                                {new Date(cell.value).toLocaleString('en-US')}
                                                            </TableCell>
                                                        );
                                                    case 'REPORTED_BY':
                                                        return (
                                                            <TableCell
                                                                className="table-row-user"
                                                                key={cell.id}
                                                                style={{ width: '15%' }}
                                                            >
                                                                {
                                                                    userListData.allUsers?.find(
                                                                        (user) => user.id === parseInt(cell.value)
                                                                    )?.name
                                                                }
                                                            </TableCell>
                                                        );
                                                    case 'UPDATE':
                                                        return userIsAbleToEdit ? (
                                                            <TableCell
                                                                className="table-row-date"
                                                                key={cell.id}
                                                                style={{ width: '15%' }}
                                                            >
                                                                {rowToEdit === row.id ? (
                                                                    <Button
                                                                        renderIcon={CheckmarkOutline}
                                                                        kind="ghost"
                                                                        iconDescription="Checkmark"
                                                                        onClick={handleSubmitEditRow}
                                                                    />
                                                                ) : (
                                                                    <Button
                                                                        renderIcon={Edit}
                                                                        kind="ghost"
                                                                        iconDescription="Pencil"
                                                                        onClick={() => handleOpenEdit(row.id)}
                                                                    />
                                                                )}
                                                            </TableCell>
                                                        ) : null;
                                                    case 'SCENARIO_NAME':
                                                    case 'UMP_PAGE':
                                                    case 'COMMENTS':
                                                    case 'downloadLinks':
                                                        return null;
                                                    case 'STATUS':
                                                        return rowToEdit === row.id ? (
                                                            <TableCell key={cell.id}>
                                                                <Dropdown
                                                                    id="status-dropdown"
                                                                    titleText=""
                                                                    label=""
                                                                    initialSelectedItem={
                                                                        rowToEdit === row.id && editedValues['status']
                                                                            ? editedValues['status']
                                                                            : cell.value
                                                                    }
                                                                    items={statusDropdown}
                                                                    onChange={({ selectedItem }) => {
                                                                        setEditedValues({
                                                                            ...editedValues,
                                                                            status: selectedItem,
                                                                        });
                                                                    }}
                                                                />
                                                            </TableCell>
                                                        ) : (
                                                            <TableCell key={cell.id}>{cell.value}</TableCell>
                                                        );
                                                    default:
                                                        return <TableCell key={cell.id}>{cell.value}</TableCell>;
                                                }
                                            })}
                                        </TableExpandRow>
                                        <TableExpandedRow
                                            colSpan={headers.length + 1}
                                            {...getExpandedRowProps({
                                                row,
                                            })}
                                        >
                                            <div
                                                className=""
                                                style={{
                                                    width: '90%',
                                                    display: 'grid',
                                                    gridTemplateColumns: '20% 20% 25% 25%',
                                                }}
                                            >
                                                {row.cells.map((cell) => {
                                                    switch (cell.info.header) {
                                                        case 'SCENARIO_NAME':
                                                            return (
                                                                <div
                                                                    key={cell.id}
                                                                    className="d-flex align-items-center"
                                                                >
                                                                    <strong>Scenario Name:</strong>{' '}
                                                                    <span style={{ paddingLeft: '4px' }}>
                                                                        {cell.value}
                                                                    </span>
                                                                </div>
                                                            );
                                                        case 'UMP_PAGE':
                                                            return (
                                                                <div
                                                                    key={cell.id}
                                                                    className="d-flex align-items-center"
                                                                >
                                                                    <strong>UMP Page:</strong>{' '}
                                                                    <span style={{ paddingLeft: '4px' }}>
                                                                        {cell.value}
                                                                    </span>
                                                                </div>
                                                            );
                                                        case 'COMMENTS':
                                                            return (
                                                                <div
                                                                    key={cell.id}
                                                                    className="d-flex align-items-center"
                                                                >
                                                                    <strong>Comments:</strong>{' '}
                                                                    {rowToEdit === row.id ? (
                                                                        <TextInput
                                                                            style={{ marginLeft: '4px' }}
                                                                            id={cell.id}
                                                                            type="text"
                                                                            labelText=""
                                                                            value={
                                                                                rowToEdit === row.id &&
                                                                                editedValues['comments'] !== undefined
                                                                                    ? editedValues['comments']
                                                                                    : cell.value ?? ''
                                                                            }
                                                                            onChange={(e) => {
                                                                                setEditedValues({
                                                                                    ...editedValues,
                                                                                    comments: e.target.value,
                                                                                });
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <span style={{ paddingLeft: '4px' }}>
                                                                            {cell.value}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            );
                                                        case 'downloadLinks':
                                                            return (
                                                                <div
                                                                    key={cell.id}
                                                                    className="d-flex align-items-center"
                                                                >
                                                                    <strong>Screenshots/Files:</strong>{' '}
                                                                    {cell.value.map((file) => {
                                                                        return (
                                                                            <Link
                                                                                href={file.link}
                                                                                style={{ paddingLeft: '4px' }}
                                                                            >
                                                                                {file.name}
                                                                            </Link>
                                                                        );
                                                                    })}
                                                                </div>
                                                            );
                                                        default:
                                                            return null;
                                                    }
                                                })}
                                            </div>
                                        </TableExpandedRow>
                                    </Fragment>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                );
            }}
        </DataTable>
    );
};

IssuesTable.propTypes = IssuesTableProps;
