import {
    Button,
    DataTable,
    TabPanel,
    Table,
    TableBatchAction,
    TableBatchActions,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableHeader,
    TableRow,
    TableSelectAll,
    TableSelectRow,
    TableToolbar,
    TableToolbarContent,
    TableToolbarSearch,
} from '@carbon/react';
import { Save } from '@carbon/icons-react';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { getScenario } from '../../wizard/scenarioApiCalls';
import { useSetAtom } from 'jotai';
import { insertScenarioApiDataAtom } from '../../../hooks/wizardAtoms';

export const ApproveScenarioTab = ({ scenarios, handleApproveModalOpen, handleRevokeApprovalModalOpen }) => {
    const insertScenarioApiData = useSetAtom(insertScenarioApiDataAtom);

    const handleLoadNewScenario = useCallback(
        async (scenarioId) => {
            window.localStorage.setItem('scenario_id', `${scenarioId}`);
            const { isOk, data: updatedScenarioFromApi } = await getScenario(scenarioId);
            if (isOk) {
                insertScenarioApiData(updatedScenarioFromApi);
            }
        },
        [insertScenarioApiData]
    );

    return (
        <TabPanel aria-label="approve-scenarios-tab">
            <DataTable
                rows={scenarios.filter(
                    (scenario) => scenario.status === 'PENDING APPROVAL' && scenario.scenario_name !== 'Default'
                )}
                headers={[
                    { header: 'Scenario Name', key: 'scenario_name' },
                    { header: 'Status', key: 'status' },
                ]}
            >
                {({
                    rows,
                    headers,
                    getHeaderProps,
                    getRowProps,
                    getSelectionProps,
                    getToolbarProps,
                    getBatchActionProps,
                    onInputChange,
                    selectedRows,
                    getTableProps,
                    getTableContainerProps,
                }) => {
                    const batchActionProps = {
                        ...getBatchActionProps(),
                    };
                    return (
                        <TableContainer
                            title="Approve Scenarios"
                            description="Please select scenarios to approve."
                            {...getTableContainerProps()}
                        >
                            <TableToolbar {...getToolbarProps()}>
                                <TableBatchActions {...batchActionProps}>
                                    <TableBatchAction
                                        tabIndex={batchActionProps.shouldShowBatchActions ? 0 : -1}
                                        renderIcon={Save}
                                        onClick={() => handleApproveModalOpen(selectedRows)}
                                    >
                                        Submit Approval
                                    </TableBatchAction>
                                </TableBatchActions>
                                <TableToolbarContent aria-hidden={batchActionProps.shouldShowBatchActions}>
                                    <TableToolbarSearch
                                        tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}
                                        onChange={onInputChange}
                                    />
                                </TableToolbarContent>
                            </TableToolbar>
                            <Table {...getTableProps()} aria-label="Approve Scenarios Table">
                                <TableHead>
                                    <TableRow>
                                        <TableSelectAll {...getSelectionProps()} />
                                        {headers.map((header, i) => (
                                            <TableHeader
                                                key={i}
                                                {...getHeaderProps({
                                                    header,
                                                })}
                                            >
                                                {header.header}
                                            </TableHeader>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {rows.map((row, i) => (
                                        <TableRow
                                            key={i}
                                            {...getRowProps({
                                                row,
                                            })}
                                        >
                                            <TableSelectRow
                                                {...getSelectionProps({
                                                    row,
                                                })}
                                            />
                                            {row.cells.map((cell) => {
                                                if (cell.info.header === 'scenario_name') {
                                                    return (
                                                        <TableCell key={cell.id}>
                                                            {cell.value}
                                                            {` `}
                                                            {`[`}
                                                            <Link
                                                                to="/manpower-wizard/review"
                                                                onClick={() => handleLoadNewScenario(row.id)}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                Review
                                                            </Link>
                                                            {`]`}
                                                        </TableCell>
                                                    );
                                                } else {
                                                    return (
                                                        <TableCell key={cell.id} style={{ width: '50%' }}>
                                                            {cell.value}
                                                        </TableCell>
                                                    );
                                                }
                                            })}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    );
                }}
            </DataTable>

            <div className="mt-3">
                <DataTable
                    rows={scenarios.filter(
                        (scenario) => scenario.status === 'APPROVED' || scenario.status === 'PUBLISHED'
                    )}
                    headers={[
                        { header: 'Scenario Name', key: 'scenario_name' },
                        { header: 'Status', key: 'status' },
                    ]}
                >
                    {({
                        rows,
                        headers,
                        getBatchActionProps,
                        getHeaderProps,
                        getRowProps,
                        getTableProps,
                        getTableContainerProps,
                        getToolbarProps,
                        onInputChange,
                    }) => {
                        const batchActionProps = {
                            ...getBatchActionProps(),
                        };
                        return (
                            <TableContainer
                                title="Approved Scenarios"
                                description="These scenarios are approved."
                                {...getTableContainerProps()}
                            >
                                <TableToolbar {...getToolbarProps()}>
                                    <TableToolbarContent aria-hidden={batchActionProps.shouldShowBatchActions}>
                                        <TableToolbarSearch
                                            tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0}
                                            onChange={onInputChange}
                                        />
                                    </TableToolbarContent>
                                </TableToolbar>
                                <Table {...getTableProps()} aria-label="Approved Scenarios Table">
                                    <TableHead>
                                        <TableRow>
                                            {headers.map((header, i) => (
                                                <TableHeader
                                                    key={i}
                                                    {...getHeaderProps({
                                                        header,
                                                    })}
                                                    style={{ width: '50%' }}
                                                >
                                                    {header.header}
                                                </TableHeader>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row, i) => (
                                            <TableRow
                                                key={i}
                                                {...getRowProps({
                                                    row,
                                                })}
                                            >
                                                {row.cells.map((cell) => {
                                                    if (cell.info.header === 'scenario_name') {
                                                        return (
                                                            <TableCell key={cell.id}>
                                                                {cell.value}
                                                                {` `}
                                                                {`[`}
                                                                <Link
                                                                    to="/manpower-wizard/review"
                                                                    onClick={() => handleLoadNewScenario(row.id)}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    Review
                                                                </Link>
                                                                {`]`}
                                                            </TableCell>
                                                        );
                                                    } else if (cell.info.header === 'status') {
                                                        return (
                                                            <TableCell key={cell.id} style={{ width: '50%' }}>
                                                                <div className="d-flex align-items-center">
                                                                    {cell.value}
                                                                    {` `}
                                                                    {cell.value === 'APPROVED' ? (
                                                                        <>
                                                                            {`[`}
                                                                            <Button
                                                                                kind="ghost"
                                                                                style={{
                                                                                    background: 'none',
                                                                                    border: 'none',
                                                                                    padding: '0',
                                                                                    alignItems: 'center',
                                                                                    textDecoration: 'underline',
                                                                                    boxShadow: 'none',
                                                                                }}
                                                                                onClick={() =>
                                                                                    handleRevokeApprovalModalOpen(
                                                                                        row.id
                                                                                    )
                                                                                }
                                                                            >
                                                                                Revoke Approval
                                                                            </Button>
                                                                            {`]`}
                                                                        </>
                                                                    ) : null}
                                                                </div>
                                                            </TableCell>
                                                        );
                                                    } else {
                                                        return <TableCell key={cell.id}>{cell.value}</TableCell>;
                                                    }
                                                })}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        );
                    }}
                </DataTable>
            </div>
        </TabPanel>
    );
};

ApproveScenarioTab.propTypes = {
    scenarios: PropTypes.arrayOf(PropTypes.object),
    handleApproveModalOpen: PropTypes.func,
    handleRevokeApprovalModalOpen: PropTypes.func,
};
