import { Link, Theme } from '@carbon/react';
import { Navigation } from '../../common';
import { CARBON_THEME, CUSTOM_THEME } from '../wizard/constants';
import { TimeoutAlert } from '../../common/TimeoutAlert';

import './Admin.scss';

export const Admin = () => {
    return (
        <Theme theme={localStorage.getItem('theme') === CUSTOM_THEME.DARK ? CARBON_THEME.G90 : CARBON_THEME.WHITE}>
            <div className="wizard" style={{ minHeight: '100vh' }}>
                <Navigation />
                <div className="m-2">
                    <h3>Admin Dashboard</h3>
                </div>
                <div className="d-flex justify-content-center">
                    <div className="mx-2">
                        <Link className="admin-tile" href={`/admin/scenarios`} data-testid="manage-scenarios-tile">
                            <h3 className="p-3">Manage Scenarios</h3>
                        </Link>
                    </div>
                    <div>
                        <Link className="admin-tile" href={`/admin/models`} data-testid="manage-scenarios-tile">
                            <h3 className="p-3">Manage Models</h3>
                        </Link>
                    </div>
                </div>
            </div>
            <TimeoutAlert />
        </Theme>
    );
};
