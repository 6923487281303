import { atom } from 'jotai';
import { focusAtom } from 'jotai-optics';
import { validateFsStatus } from '../opsMx/helpers/validateFsStatus';

// General Settings Atoms
export const filtersAtom = atom([]);
export const ILCFiltersAtom = atom([]);
export const MDSFiltersAtom = atom([]);
export const CMDFiltersAtom = atom([]);

export const filteredDataTableAtom = atom([]);
export const baselineMPESDataAtom = atom([]);
export const filteredPecAtom = atom([]);

export const initialGsFilters = {
    MDS: '',
    ILC: '',
    CMD: '',
};
export const gsFiltersAtom = atom(initialGsFilters);
export const gsFilterChangeAtom = atom(false);

export const addMDSModalOpenAtom = atom(false);
export const addUnitModalOpenAtom = atom(false);

export const fsTableMessageAtom = atom('Please make a selection for MDS, ILC, and CMD.');
/**
 *
 * @param {{PARENT: any, CHILDREN: any[]}[]} table
 * @returns {{[unit: string]: number}}
 */
export function flattenForceStructureUnits(table) {
    const units = {};
    let children = [];
    // Base case nothing in table
    if (!table || table.length === 0) {
        return {};
    }
    table.forEach((row) => {
        if (row.PARENT && row.CHILDREN) {
            units[row.PARENT.UNIT] = row.PARENT.PMAI;
            children = children.concat(row.CHILDREN);
        }
    });

    return {
        ...units,
        ...flattenForceStructureUnits(children),
    };
}

export const forceStructureUnitsAtom = atom((get) => {
    const [forceStructureTables] = get(filteredDataTableAtom);

    return flattenForceStructureUnits(forceStructureTables);
});

export function flattenForceStructureStaticUnits(table) {
    const units = {};
    let children = [];
    // Base case nothing in table
    if (!table || table.length === 0) {
        return {};
    }
    table.forEach((row) => {
        if (row.PARENT && row.CHILDREN) {
            units[row.PARENT.UNIT] = {
                PMAI: row.PARENT.PMAI,
                PTAI: row.PARENT.PTAI,
                PDAI: row.PARENT.PDAI,
                // POAI: row.PARENT.POAI,
                BAI: row.PARENT.BAI,
                AR: row.PARENT.AR,
            };
            children = children.concat(row.CHILDREN);
        }
    });

    return {
        ...units,
        ...flattenForceStructureStaticUnits(children),
    };
}

export const forceStructureStaticAtom = atom((get) => {
    const [forceStructureTables] = get(filteredDataTableAtom);
    return flattenForceStructureStaticUnits(forceStructureTables);
});

export const baselineMPESDataStaticAtom = atom((get) => {
    const [baselineMPESData] = get(baselineMPESDataAtom);
    return flattenForceStructureStaticUnits(baselineMPESData);
});

const _forceStructureAdjustableAtom = atom({});
export const forceStructureAdjustableAtom = atom(
    (get) => get(_forceStructureAdjustableAtom),
    (get, set, update) => {
        set(_forceStructureAdjustableAtom, update);
        set(hasOnePaiTypeAtom, validateFsStatus(update));
    }
);

export const hasOnePaiTypeAtom = atom(true);

export const mdsFilterAtom = focusAtom(gsFiltersAtom, (optics) => optics.prop('MDS'));

export const cmdFilterAtom = focusAtom(gsFiltersAtom, (optics) => optics.prop('CMD'));
export const ilcFilterAtom = focusAtom(gsFiltersAtom, (optics) => optics.prop('ILC'));
// fetched filter atoms for fs table to compare if filters changed
export const fetchedFiltersAtom = atom({ MDS: '', ILC: '', CMD: '' });

// merge unit data from baseline and scenario
export const getCombinedAdjustmentData = (staticUnits, scenario) => {
    const adjData = scenario?.generalSettings?.forceStructureTables?.adjustment || [];
    let scenarioUnits = {};
    adjData
        ?.filter((row) => !!staticUnits[row.UNIT])
        .forEach((row) => {
            scenarioUnits[row.UNIT] = row;
        });
    return { ...staticUnits, ...scenarioUnits };
};
