import React, { useEffect, useState } from 'react';
import {
    DataTable,
    DataTableSkeleton,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableHeader,
    TableRow,
} from '@carbon/react';
import PropTypes from 'prop-types';
import { fetchMxModelDetailsOptionA } from '../../wizard/maintenanceApiCalls';

/**
 *
 * @type {React.FC<PropTypes.InferProps<typeof MxModelInfoModalProps>>}
 * @returns {React.ReactElement}
 */
export const MxModelInfoModal = ({ open, onClose, model }) => {
    const [modelInfo, setModelInfo] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    function getTableHeaders(keys) {
        const headers = [];
        keys.filter((key) => key !== 'id').forEach((key) => {
            const header = key.split('_').join(' ');
            headers.push({ header, key });
        });
        return headers;
    }

    useEffect(() => {
        async function fetchWorkcenterDataTypeA(mxId, ilc) {
            try {
                setIsLoading(true);
                const { workcentersData } = await fetchMxModelDetailsOptionA(mxId, ilc);
                setModelInfo(workcentersData);
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        }
        if (model) {
            fetchWorkcenterDataTypeA(model.MX_ID, model.ILC);
        }
    }, [model]);

    const tableHeaders = modelInfo.length > 0 ? getTableHeaders(Object.keys(modelInfo[0])) : [];
    return (
        <Modal
            passiveModal
            open={open}
            size="lg"
            className="mx-model-info-modal"
            onRequestClose={onClose}
            modalLabel={model.MODEL_NAME}
            data-testid="mx-model-info-modal"
        >
            {isLoading ? (
                <DataTableSkeleton headers={[]} />
            ) : (
                <DataTable rows={modelInfo} headers={tableHeaders}>
                    {({ rows, headers, getHeaderProps, getRowProps, getTableProps, getTableContainerProps }) => {
                        return (
                            <TableContainer {...getTableContainerProps()}>
                                <Table {...getTableProps()}>
                                    <TableHead>
                                        <TableRow>
                                            {headers.map((header) => (
                                                <TableHeader {...getHeaderProps({ header })}>
                                                    {header.header}
                                                </TableHeader>
                                            ))}
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {rows.map((row) => (
                                            <TableRow {...getRowProps({ row })}>
                                                {row.cells.map((cell) => (
                                                    <TableCell key={cell.id}>{cell.value}</TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        );
                    }}
                </DataTable>
            )}
        </Modal>
    );
};

const MxModelInfoModalProps = {
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    model: PropTypes.object.isRequired,
};

MxModelInfoModal.propTypes = MxModelInfoModalProps;
