import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useAtom, useSetAtom } from 'jotai';
import { Button, InlineNotification } from '@carbon/react';
import { Add } from '@carbon/react/icons';

import { EquationsTable } from '../opsMx/EquationsTable';
import { MxEquations } from '../../modals/mxEquations/MxEquations';
import {
    mxAsisPaginationAtom,
    mxAsisRowsAtom,
    mxTobePaginationAtom,
    mxTobeRowsAtom,
    mxAsisSelectionsAtom,
    insertMxTooltipsAtom,
    mxTooltipsAtom,
    mxSelectedDropdownDataAtom,
    // flattenForceStructureUnits,
} from './MxAtoms';
import { SelectedFilterTags } from '../opsMx/SelectedFilterTags';
import { gsFiltersAtom } from '../generalSettings/GeneralSettingsAtoms';
import { checkUnitDetailsCompletedAtom, selectedMxgPecsAtom } from '../unitDetails/UnitDetailsAtoms';
import { mxHeaders } from './MxHeaders';
import { RecursiveTable } from '../opsMx/RecursiveTable';
import { insertScenarioApiDataAtom, isApprovedScenarioAtom, scenarioAtom } from '../../../../hooks/wizardAtoms';
import { useFocusAtom, useIsMounted } from '../../../../hooks';
import { useSaveScenario } from '../../../../hooks/useSaveScenario';
import { handleRowsSelected } from '../opsMx/helpers/handleRowsSelected';
import { getRowExclusions } from '../opsMx/helpers/getRowExclusions';
import { fetchMxAsis, fetchMxTobe } from '../../maintenanceApiCalls';
import { saveAllExclusions } from '../opsMx/helpers/useExclusions';
import { newRowsToExclude } from '../opsMx/helpers/newRowsToExclude';
import { getScenario } from '../../scenarioApiCalls';
import { FacMsiApiDropdowns } from '../opsMx/FacMsiApiDropdowns';
import { Banner } from '../opsMx/Banner';

export const Mx = () => {
    const [gsFilters] = useAtom(gsFiltersAtom);
    const [selectedMxgPecs] = useAtom(selectedMxgPecsAtom);
    const [mxAsisRows, setMxAsisRows] = useAtom(mxAsisRowsAtom);
    const [mxTobeRows, setMxTobeRows] = useAtom(mxTobeRowsAtom);
    const [isLoading, setIsLoading] = useState(false);
    const [isTobeLoading, setIsTobeLoading] = useState(false);
    const [mxOpen, setMxOpen] = useState(false);
    const [scenario_id] = useFocusAtom(scenarioAtom, 'scenario_id');
    const [mxAsisPagination, setMxAsisPagination] = useAtom(mxAsisPaginationAtom);
    const { isSaving } = useSaveScenario();
    const [mxTobePagination, setMxTobePagination] = useAtom(mxTobePaginationAtom);
    const [asisRowsSelected, setAsisRowsSelected] = useAtom(mxAsisSelectionsAtom);
    const [mxTooltips] = useAtom(mxTooltipsAtom);
    const [isApprovedScenario] = useAtom(isApprovedScenarioAtom);
    const [dropdownData, setDropdownData] = useState({ API: [], FAC: [], MSI: [] });
    const [selectedDropdownData, setSelectedDropdownData] = useAtom(mxSelectedDropdownDataAtom);
    //save to scenario
    const [tobeRowsSelected, setTobeRowsSelected] = useState({});
    const [exclusions, setExclusions] = useState([]);
    const [asisIsMissing, setAsisIsMissing] = useState(false);
    const [banner, setBanner] = useState({ isMissing: false, isUnconventional: false });
    const [unitDetailsValidation] = useAtom(checkUnitDetailsCompletedAtom);
    const isMounted = useIsMounted();

    const insertScenarioApiData = useSetAtom(insertScenarioApiDataAtom);
    const insertMxTooltips = useSetAtom(insertMxTooltipsAtom);

    const { contractMXGSelected } = unitDetailsValidation;

    const hasGeneralSettings = useMemo(() => {
        const { MDS, ILC, CMD } = gsFilters;
        return MDS.length > 0 && ILC.length > 0 && CMD.length > 0;
    }, [gsFilters]);

    const fetchMxASISData = useCallback(async () => {
        try {
            if (hasGeneralSettings) {
                setIsLoading(true);
                const { data, dropdowns, tooltips, isMissing } = await fetchMxAsis(scenario_id);
                if (!isMounted()) {
                    return;
                }
                insertMxTooltips(tooltips);
                setAsisIsMissing(isMissing);

                const exclusions = getRowExclusions(data);
                const mappedData = {
                    API: dropdowns.API,
                    FAC: dropdowns.FAC.filter((item) => item.CHECKED),
                    MSI: dropdowns.MSI.filter((item) => item.CHECKED),
                };
                setMxAsisRows(data);
                setExclusions(exclusions);
                setDropdownData(dropdowns);
                setSelectedDropdownData(mappedData);
                setMxAsisPagination({ page: 1, pageSize: 10 });
            } else {
                setMxAsisRows([]);
            }
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    }, [
        hasGeneralSettings,
        insertMxTooltips,
        scenario_id,
        setMxAsisRows,
        setSelectedDropdownData,
        setMxAsisPagination,
        isMounted,
    ]);

    const fetchMxTOBEData = useCallback(async () => {
        try {
            if (hasGeneralSettings) {
                setIsTobeLoading(true);
                const { data, tooltips, isMissing, isUnconventional } = await fetchMxTobe(scenario_id);
                if (!isMounted()) {
                    return;
                }
                insertMxTooltips(tooltips);

                setBanner({ isMissing, isUnconventional });
                setMxTobeRows(data);
                setIsTobeLoading(false);
                setMxTobePagination({ page: 1, pageSize: 10 });
            } else {
                setMxTobeRows([]);
            }
        } catch (error) {
            console.error(error);
            setIsTobeLoading(false);
        }
    }, [hasGeneralSettings, insertMxTooltips, scenario_id, setMxTobeRows, setMxTobePagination, isMounted]);

    useEffect(() => {
        if (isSaving || scenario_id === -1) return;
        fetchMxASISData();
        fetchMxTOBEData();
    }, [isSaving, scenario_id, fetchMxASISData, fetchMxTOBEData]);

    const onEdit = () => {
        setMxOpen(true);
    };

    const ASISTitle = (
        <div className="d-flex justify-content-between align-items-center">
            <p>
                <strong>ASIS</strong> - Current Requirements Based on MPES/UMD
            </p>
            {mxTobeRows.length === 0 ? (
                <Button
                    kind="tertiary"
                    size="md"
                    disabled={isApprovedScenario}
                    renderIcon={Add}
                    iconDescription="plus sign"
                    onClick={() => {
                        setMxOpen(true);
                    }}
                >
                    Add Model
                </Button>
            ) : null}
        </div>
    );
    const TOBETitle = (
        <div className="d-flex justify-content-between align-items-center">
            <p>
                <strong>TOBE</strong> - Calculated Requirements Based on PAI at the Workcenter Level
            </p>
            {mxTobeRows.length > 0 && (
                <Button kind="tertiary" size="md" onClick={onEdit} data-testid="select-model-button">
                    Select Model
                </Button>
            )}
        </div>
    );

    const handleSelection = async (isSelected, _, parentId, cellId) => {
        handleRowsSelected({
            isSelected,
            parentId,
            cellId,
            setRowsSelected: setAsisRowsSelected,
        });
    };

    const calculateTOBE = async () => {
        const { success } = await saveAllExclusions({
            exclusionData: newRowsToExclude({ rows: mxAsisRows, rowsSelected: asisRowsSelected, scenario_id }),
            mpType: 'MX',
        });
        if (success) {
            fetchMxASISData();
            fetchMxTOBEData();
        }
    };

    const handleTobeSelection = (isSelected, parentId, cellId) => {
        if (!isSelected) {
            setTobeRowsSelected((previousRowsSelected) => ({
                ...previousRowsSelected,
                [parentId]: tobeRowsSelected[parentId]?.filter((selectedId) => !cellId.includes(selectedId)),
            }));
        } else {
            setTobeRowsSelected((previousRowsSelected) => ({
                ...previousRowsSelected,
                [parentId]: tobeRowsSelected[parentId]
                    ? [...new Set(previousRowsSelected[parentId].concat(cellId))]
                    : [...cellId],
            }));
        }
    };

    const handleSubmit = async () => {
        try {
            setIsTobeLoading(true);
            const { data } = await fetchMxTobe(scenario_id);
            const { isOk, data: updatedScenarioFromApi } = await getScenario(scenario_id);
            if (!isMounted()) {
                return;
            }
            if (isOk) {
                insertScenarioApiData(updatedScenarioFromApi);
            }
            setMxTobeRows(data);
        } catch (error) {
            console.error(error);
        }
        setIsTobeLoading(false);
    };

    const handleInitSelections = (tableId, selections) => {
        tableId === 'mxAsis' ? setAsisRowsSelected(selections) : setTobeRowsSelected(selections);
    };

    if (contractMXGSelected()) {
        return (
            <div className="inline-notification-wrapper">
                <InlineNotification
                    title="Contract Maintenance Selected"
                    hideCloseButton
                    kind="error"
                    data-testid="mx-contract-mx-selected"
                />
            </div>
        );
    }

    if (selectedMxgPecs.length === 0) {
        return (
            <div className="inline-notification-wrapper">
                <InlineNotification
                    title="Missing MXG PEC Selection"
                    subtitle="Please make a selection for MXG PEC"
                    hideCloseButton
                    kind="error"
                    data-testid="mx-missing-pec-wrapper"
                />
            </div>
        );
    }

    return (
        <div className="tab-content">
            {asisIsMissing || banner.isMissing ? <Banner type="missing" /> : null}
            {banner.isUnconventional ? <Banner type="unconventional" /> : null}
            <div className="header-content">
                <SelectedFilterTags />
                <FacMsiApiDropdowns
                    dropdownItems={dropdownData}
                    selectedFilters={selectedDropdownData}
                    setSelectedFilters={setSelectedDropdownData}
                    mpType="MX"
                    disabled={isApprovedScenario}
                    isLoading={isLoading}
                    setAsisRows={setMxAsisRows}
                    setExclusions={setExclusions}
                    setIsLoading={setIsLoading}
                />
            </div>
            <EquationsTable
                id="mxAsis"
                headers={mxHeaders}
                rows={mxAsisRows}
                isLoading={isLoading}
                disabled={isApprovedScenario}
                title={ASISTitle}
                tooltips={mxTooltips}
                page={mxAsisPagination.page}
                pageSize={mxAsisPagination.pageSize}
                onPageSizeChange={setMxAsisPagination}
                selectedIds={asisRowsSelected}
                exclusions={exclusions}
                onSelect={handleSelection}
                onInit={handleInitSelections}
                calculateTOBE={calculateTOBE}
            />
            <RecursiveTable
                id="mxTobe"
                rows={mxTobeRows}
                isLoading={isTobeLoading}
                disabled={isApprovedScenario}
                headers={mxHeaders}
                title={TOBETitle}
                tooltips={mxTooltips}
                page={mxTobePagination.page}
                pageSize={mxTobePagination.pageSize}
                onPageSizeChange={setMxTobePagination}
                selectedIds={tobeRowsSelected}
                onSelect={handleTobeSelection}
                onInit={handleInitSelections}
            />
            {mxOpen && (
                <MxEquations
                    open={mxOpen}
                    onClose={() => {
                        setMxOpen(false);
                    }}
                    onSubmit={handleSubmit}
                />
            )}
        </div>
    );
};
