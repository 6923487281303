import axios from 'axios';

/**
 *
 * @param {{
 *      cmd: string;
 *      mds: string;
 *      ilc: string;
 *      pos: 'OFF' | 'ENL' | 'CIV'
 * }} param0
 * @returns
 */
export const fetchOpsFullTimeEquation = async ({ cmd, mds, ilc, aircrew_type = 'OFF' }) => {
    try {
        const { data: dataList } = await axios.post('/api/fast-api/aircrew/fulltime_eqns/', null, {
            params: {
                cmd,
                mds,
                ilc,
                aircrew_type,
            },
        });

        return {
            isOk: true,
            error: null,
            data: dataList.map((data) => ({
                aircrewType: data.AIRCREW_TYPE ?? aircrew_type,
                maf: data.MAF ?? 1,
                constant: data.CONSTANT,
                coefficient: data.COEFFICIENT,
                inputs: data.WLF.QUERY?.map?.((input, index) => ({
                    field: input.FIELD,
                    criteria: input.CRITERIA,
                    search: input.REGEX,
                    id: index + 1,
                })),
                osc: data.OSC,
                fac: data.FAC,
                iaf: data.IAF,
                afsc: data.AFSC,
                eqnId: `${data.EQN_ID}`,
                wlf: data.WLF,
                wlfType: data.WLF_TYPE, // CUSTOM VALUE | SEARCH CRITERIA
                queryValue: data.VALUE ?? null,
                customValue: data.WLF.VALUE ?? 0,
                ilcSpecific: data.ILC ?? 'ALL',
                value: data.WLF_VALUE,
                isBaseline: data.BASELINE === 1 || data.BASELINE === '1',
            })),
        };
    } catch (error) {
        return {
            isOk: false,
            data: null,
            error,
        };
    }
};

/**
 *
 * @param {number} scenarioId
 * @param {{
 *      cmd: string,
 *      mds: string,
 *      fac?: string,
 *      osc?: string,
 *      afsc?: string,
 *      iaf?: string | number,
 *      maf?: number,
 *      aircrew_type: "OFF" | "ENL" | "CIV",
 *      wlf: {
 *          value?: number | string;
 *          query?: {
 *              field: string;
 *              criteria: string;
 *              regex: string;
 *          }[]
 *      },
 *      constant?: 0,
 *      coefficient?: 0
 * }} body
 */
export const createFulltimeEquation = async (scenarioId, body) => {
    try {
        await axios.post('/api/fast-api/aircrew/create_eqn', body, {
            params: { scenario_id: scenarioId },
        });
        return {
            isOk: true,
            error: null,
        };
    } catch (error) {
        return {
            isOk: false,
            error,
        };
    }
};

/**
 *
 * @param {number} scenarioId
 * @param {{
 *  eqn_id: string | number,
 *  fac: string,
 *  osc: string,
 *  afsc: string,
 *  pos: string,
 *  wlf: {
 *          value?: number | string;
 *          query?: {
 *              field: string;
 *              criteria: string;
 *              regex: string;
 *          }[]
 *      },
 *  constant: string | number,
 *  coefficient: string | number,
 * }} body
 */
export const editFulltimeEquation = async (scenarioId, body) => {
    try {
        const { maf } = body;
        await axios.post(
            '/api/fast-api/aircrew/update_eqn',
            {
                ...body,
                maf: maf ?? 1,
            },
            {
                params: { scenario_id: scenarioId },
            }
        );
        return {
            isOk: true,
            error: null,
        };
    } catch (error) {
        return {
            isOk: false,
            error,
        };
    }
};

/**
 *
 * @param {string} scenario_id
 * @param {string} eqnId
 * @param {boolean} archive
 * @returns
 */
export const deleteOpsFulltimeEquation = async (scenarioId, eqnId, archive = true) => {
    try {
        await axios.post('/api/fast-api/aircrew/delete_eqn', null, {
            params: { eqn_id: eqnId, scenario_id: scenarioId, archive },
        });
        return {
            isOk: true,
            error: null,
        };
    } catch (error) {
        return {
            isOk: false,
            error,
        };
    }
};

/**
 *
 * @param {{cmd: string; mds: string}} params
 * @returns
 */
export const fetchAircrewModels = async (params = {}) => {
    try {
        const { mds, cmd } = params;
        const { data } = await axios.post('/api/fast-api/aircrew/models', null, { params: { cmd, mds } });

        return {
            isOk: true,
            error: null,
            data,
        };
    } catch (error) {
        return {
            isOk: false,
            data: null,
            error,
        };
    }
};

/**
 *
 * @param {string | number} scenarioId
 * @returns
 */
export const fetchAircrewAsis = async (scenarioId) => {
    try {
        const { data } = await axios.post('/api/fast-api/aircrew/asis', null, { params: { scenario_id: scenarioId } });
        let isMissing = false;
        const facTitles = {};
        const pecTitles = {};
        const ricTitles = {};
        const asisRows = data.ASIS.map((row) => {
            isMissing = isMissing || row.PARENT.MISSING;
            pecTitles[row.PARENT.PEC] = row.PARENT.PEC_TITLE;
            return {
                ...row,
                CHILDREN: row.CHILDREN.map((child) => {
                    isMissing = isMissing || child.MISSING;
                    facTitles[child.FAC] = child.FAC_TITLE;
                    ricTitles[child.RIC] = child.RIC_TITLE;
                    return {
                        ...child,
                        parentId: `opsAsis_${row.PARENT.ID}`,
                        id: `opsAsis_${child.ID}`,
                    };
                }),
                id: `opsAsis_${row.PARENT.ID}`,
            };
        });
        return {
            isOk: true,
            data: asisRows,
            dropdowns: data.DROPDOWN,
            tooltips: {
                facTitles,
                pecTitles,
                ricTitles,
            },
            isMissing,
            error: null,
        };
    } catch (error) {
        return {
            isOk: false,
            data: [],
            dropdowns: {
                FAC: [],
                API: [],
                MSI: [],
            },
            tooltips: {
                facTitles: {},
                pecTitles: {},
                ricTitles: {},
            },
            isMissing: false,
            error,
        };
    }
};

/**
 *
 * @param {string | number} scenarioId
 * @returns
 */
export const fetchAircrewTobe = async (scenarioId) => {
    try {
        const { data } = await axios.post('/api/fast-api/aircrew/tobe', null, {
            params: { scenario_id: scenarioId },
        });
        let isMissing = false;
        const facTitles = {};
        const pecTitles = {};
        const ricTitles = {};
        const formattedData = data.map((row) => {
            isMissing = isMissing || row.PARENT.MISSING;
            pecTitles[row.PARENT.PEC] = row.PARENT.PEC_TITLE;
            return {
                ...row,
                CHILDREN: row.CHILDREN.map((child) => {
                    isMissing = isMissing || child.MISSING;
                    facTitles[child.FAC] = child.FAC_TITLE;
                    ricTitles[child.RIC] = child.RIC_TITLE;
                    return {
                        ...child,
                        parentId: `opsTobe_${row.PARENT.ID}`,
                        id: `opsTobe_${child.ID}`,
                    };
                }),
                id: `opsTobe_${row.PARENT.ID}`,
            };
        });

        return {
            isOk: true,
            data: formattedData,
            tooltips: {
                facTitles,
                pecTitles,
                ricTitles,
            },
            isMissing,
            error: null,
        };
    } catch (error) {
        return {
            isOk: false,
            data: [],
            tooltips: {
                facTitles: {},
                pecTitles: {},
                ricTitles: {},
            },
            isMissing: false,
            error,
        };
    }
};

/**
 *
 * @param {{
 *      PARENT: {
 *          ACR_ID: number,
 *          CMD: string,
 *          MDS: string,
 *          MODEL_NAME: string,
 *      };
 *      CHILDREN: {
 *          AFSC: string,
 *          NOTES: string,
 *          POC: string,
 *          SOURCE: string,
 *          ARCRW_RATIO: number,
 *          ARCRW_COMP_ENL: number,
 *          ARCRW_COMP_OFF: number,
 *          CREW_POSITION: number,
 *          TABLE_DATE: string,
 *      }[]
 * }} body
 * @param {string | number} scenarioId
 */
export const createAircrewModel = async (body, scenarioId) => {
    try {
        await axios.post('/api/fast-api/aircrew/upload_aircrew_model', body, {
            params: { scenario_id: scenarioId },
        });
        return {
            isOk: true,
            error: null,
        };
    } catch (error) {
        return {
            isOk: false,
            error,
        };
    }
};

/**
 *
 * @param {{
 *      PARENT: {
 *          ACR_ID: number,
 *          CMD: string,
 *          MDS: string,
 *          MODEL_NAME: string,
 *      };
 *      CHILDREN: {
 *          AFSC: string,
 *          FAC: string,
 *          NOTES: string,
 *          POC: string,
 *          SOURCE: string,
 *          ARCRW_RATIO: number,
 *          ARCRW_COMP_ENL: number,
 *          ARCRW_COMP_OFF: number,
 *          CREW_POSITION: number,
 *          TABLE_DATE: string,
 *      }[]
 * }} body
 * @param {string | number} scenarioId
 */
export const updateAircrewModel = async (body, scenarioId) => {
    try {
        await axios.post('/api/fast-api/aircrew/update_aircrew_model', body, {
            params: { scenario_id: scenarioId },
        });
        return {
            isOk: true,
            error: null,
        };
    } catch (error) {
        return {
            isOk: false,
            error,
        };
    }
};

/**
 *
 * @param {string | number} acrId
 * @returns
 */
export const deleteAircrewModel = async (acrId, archive = true) => {
    try {
        await axios.post('/api/fast-api/aircrew/delete_model', null, {
            params: { acr_id: acrId, archive },
        });
        return {
            isOk: true,
            error: null,
        };
    } catch (error) {
        return {
            isOk: false,
            error,
        };
    }
};

/**
 *  Updates the OPS portion of the scenario
 *
 *  Omitting body will clear the OPS portion of scenario
 * @param {number} scenario_id
 * @param {{
 *  UNIT: string,
 *  PAI_TYPE: string,
 *  PAI_QTY: number,
 *  NTI: number,
 *  ACR_ID: number,
 *  PEC: string,
 *  RIC: string,
 * }} body
 * @returns
 */
export const updateOpsQty = async (
    scenario_id,
    body = {
        UNIT: null,
        MX_UNIT: null,
        PEC: null,
        RIC: null,
        MX_ID: null,
        PAI_TYPE: null,
        PAI_QTY: null,
        NTI: null,
    }
) => {
    try {
        const { data } = await axios.post('/api/fast-api/aircrew/update_qty', body, {
            params: { scenario_id: scenario_id },
        });
        return {
            isOk: true,
            error: null,
            data,
        };
    } catch (error) {
        return {
            isOk: false,
            data: null,
            error,
        };
    }
};

/**
 *
 * @param {{filter_data: {[key: string]: any}}} filterData
 * @param {number} scenario_id
 * @returns
 */
export const fetchTempOpsAsis = async ({ filterData, scenario_id = 1 }) => {
    try {
        const { data } = await axios.post('/api/fast-api/aircrew/temp_session_asis', filterData, {
            params: { scenario_id },
        });

        let isMissing = false;
        const facTitles = {};
        const pecTitles = {};
        const ricTitles = {};
        const asisRows = data.map((row) => {
            isMissing = isMissing || row.PARENT.MISSING;
            pecTitles[row.PARENT.PEC] = row.PARENT.PEC_TITLE;
            return {
                ...row,
                CHILDREN: row.CHILDREN.map((child) => {
                    isMissing = isMissing || child.MISSING;
                    facTitles[child.FAC] = child.FAC_TITLE;
                    ricTitles[child.RIC] = child.RIC_TITLE;
                    return {
                        ...child,
                        parentId: `opsAsis_${row.PARENT.ID}`,
                        id: `opsAsis_${child.ID}`,
                    };
                }),
                id: `opsAsis_${row.PARENT.ID}`,
            };
        });

        return {
            isOk: true,
            data: asisRows,
            tooltips: {
                facTitles,
                pecTitles,
                ricTitles,
            },
            isMissing,
            error: null,
        };
    } catch (error) {
        return {
            isOk: false,
            data: [],
            tooltips: {
                facTitles: {},
                pecTitles: {},
                ricTitles: {},
            },
            isMissing: false,
            error,
        };
    }
};

/**
 *
 * @param {string} acrId
 * @returns
 */

export const fetchAircrewModelData = async (acrId) => {
    try {
        const { data } = await axios.post('/api/fast-api/aircrew/model_data', null, { params: { acr_id: acrId } });

        return {
            isOk: true,
            error: null,
            data,
        };
    } catch (error) {
        return {
            isOk: false,
            data: null,
            error,
        };
    }
};
