import {
    Button,
    DataTable,
    Table,
    TableBody,
    TableCell,
    TableExpandedRow,
    TableExpandHeader,
    TableExpandRow,
    TableRow,
    TableSelectRow,
    Tooltip,
} from '@carbon/react';
import { Add } from '@carbon/react/icons';
import { atom, useAtom } from 'jotai';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { TableHeaderContent, HeaderContent } from './EquationsTableChildren';
import { TableLoading } from './EquationsTableChildren/TableLoading';
import { compare } from './helpers/compare';
import { useSelectAllRows } from './helpers/useSelectAllRows';
import { TablePagination } from './TablePagination';
import PropTypes from 'prop-types';
import { useInitExclusions } from './helpers/useInitExclusions';
import { CalculateTobeModal } from '../../modals/calculateTobe/CalculateTobeModal';

export const sortInfoAtom = atom({
    columnId: undefined,
    sortDirection: 'none',
});

export const EquationsTable = ({
    id,
    title,
    headers = [],
    rows = [],
    isLoading = false,
    addRowButton = false,
    onModalChange,
    page,
    pageSize,
    tooltips,
    selectedIds = [],
    exclusions = [],
    disabled = false,
    onPageSizeChange = () => {},
    onSelect = () => {},
    onInit = () => {},
    calculateTOBE = () => {},
    className = '',
}) => {
    const [sortInfo] = useAtom(sortInfoAtom);
    const [qtySum, setQtySum] = useState({});
    const [calculateTobeModalOpen, setCalculateTobeModalOpen] = useState(false);

    const rowsRef = useRef(rows);
    const exclusionsRef = useRef(exclusions);

    const sortedRows = useMemo(() => {
        const { columnId, sortDirection } = sortInfo;
        const parentHeaders = new Set(['UNIT', 'PEC', 'RIC', 'PAI_TYPE', 'PAI_QTY']);
        let sortedRows = rows;
        if (parentHeaders.has(columnId)) {
            sortedRows =
                sortDirection === 'none'
                    ? rows
                    : rows
                          .slice()
                          .sort(
                              (lhs, rhs) =>
                                  (sortDirection === 'ascending' ? 1 : -1) *
                                  compare(lhs.PARENT[columnId], rhs.PARENT[columnId])
                          );
        } else {
            sortedRows =
                sortDirection === 'none'
                    ? rows
                    : rows.slice().map((row) => {
                          return {
                              ...row,
                              CHILDREN: row.CHILDREN.sort(
                                  (lhs, rhs) =>
                                      (sortDirection === 'ascending' ? 1 : -1) * compare(lhs[columnId], rhs[columnId])
                              ),
                          };
                      });
        }
        return sortedRows;
    }, [rows, sortInfo]);

    const paginatedRows = useMemo(() => {
        return sortedRows.slice((page - 1) * pageSize).slice(0, pageSize);
    }, [sortedRows, page, pageSize]);

    const getChildRows = ({ rowId }) => {
        const row = paginatedRows.find((row) => row.id === rowId);
        return row ? row.CHILDREN : [];
    };

    const renderCell = ({
        tableId,
        cellId,
        col,
        row,
        value,
        selectedIds = [],
        childRowId,
        parentId,
        qty = 0,
        isParentRow,
        isMissing,
        isCarryover,
        isAdditional,
        onSelect = () => {},
    }) => {
        let className = isMissing ? `amber-background table-cell-${col}` : `table-cell-${col}`;
        className = `${className} ${isAdditional ? 'blue-background' : ''}`;
        switch (col) {
            case 'RIC':
                if (isParentRow) {
                    return (
                        <TableCell key={cellId} className={className}>
                            <Tooltip align="right" label={tooltips.ricTitles[value]}>
                                <Button kind="ghost" size="sm" style={{ alignItems: 'center' }}>
                                    {value}
                                </Button>
                            </Tooltip>
                        </TableCell>
                    );
                } else {
                    return (
                        <TableCell key={cellId} className={className}>
                            {' '}
                        </TableCell>
                    );
                }
            case 'FAC':
                if (value) {
                    return (
                        <TableCell key={cellId} className={className}>
                            <Tooltip align="right" label={tooltips.facTitles[value]}>
                                <Button kind="ghost" size="sm" style={{ alignItems: 'center' }}>
                                    {value}
                                </Button>
                            </Tooltip>
                        </TableCell>
                    );
                }
                return (
                    <TableCell key={cellId} className={className}>
                        {' '}
                    </TableCell>
                );
            case 'UNIT':
            case 'PEC':
            case 'PAI_TYPE':
                if (!isParentRow) {
                    // For child rows do not display parent's identifier columns
                    return <TableCell key={cellId} className={className}></TableCell>;
                } else {
                    if (col === 'PEC') {
                        return (
                            <TableCell key={cellId} className={className}>
                                <Tooltip align="right" label={tooltips.pecTitles[value]}>
                                    <Button kind="ghost" size="sm" style={{ alignItems: 'center' }}>
                                        {value}
                                    </Button>
                                </Tooltip>
                            </TableCell>
                        );
                    }
                    return (
                        // same as default
                        <TableCell key={cellId} className={className}>
                            {value}
                        </TableCell>
                    );
                }
            case 'checkbox':
                return (
                    <TableSelectRow
                        className={`table-cell-${col}`}
                        disabled={disabled}
                        key={childRowId}
                        id={childRowId}
                        ariaLabel="Select row for calculation"
                        checked={selectedIds[parentId]?.includes(childRowId) ?? false}
                        onSelect={(e) => {
                            onSelect(e.target.checked, tableId, parentId, [childRowId]);
                            setQtySum({
                                ...qtySum,
                                [row.id]: e.target.checked
                                    ? qtySum[row.id]
                                        ? qtySum[row.id] + qty
                                        : qty
                                    : qtySum[row.id] - qty,
                            });
                        }}
                        name={`select-${childRowId}`}
                    />
                );
            case 'QTY':
                let qtyValue = isParentRow ? qtySum[row.id] ?? value : value;
                return (
                    <TableCell key={cellId} className={className}>
                        {isCarryover ? (
                            <Tooltip align="left" label={'Carryover number from ASIS'}>
                                <Button kind="ghost" size="sm" style={{ alignItems: 'center' }}>
                                    {qtyValue}
                                    <strong>*</strong>
                                </Button>
                            </Tooltip>
                        ) : (
                            qtyValue
                        )}
                    </TableCell>
                );
            case 'ID':
            case 'parentId':
            case 'RIC_TITLE':
            case 'FAC_TITLE':
            case 'PEC_TITLE':
            case 'id':
            case 'EXCLUDE':
            case 'MISSING':
            case 'CARRYOVER':
            case 'ADDITIONAL':
            case 'UNCONVENTIONAL':
                return null;

            default:
                return (
                    <TableCell key={cellId} className={className}>
                        {value}
                    </TableCell>
                );
        }
    };

    const getCellValue = ({ rowId, isParent, header }) => {
        const row = paginatedRows.find((row) => row.id === rowId);
        if (!row) {
            return '';
        }
        if (isParent) {
            return row.PARENT[header] ? row.PARENT[header] : '';
        } else {
            return row.CHILDREN[header];
        }
    };

    const selectAllChildRows = useCallback(
        ({ isSelected, tableId, rowId }) => {
            const row = paginatedRows.find((row) => row.id === rowId);
            if (row) {
                onSelect(
                    isSelected,
                    tableId,
                    row.id,
                    row.CHILDREN.map((child) => `${id}_${child.ID}`)
                );
                const totalSum = row.CHILDREN.map((child) => child['QTY']).reduce((acc, current) => acc + current);
                setQtySum({
                    ...qtySum,
                    [row.id]: isSelected ? totalSum : 0,
                });
            }
        },
        [id, onSelect, setQtySum, qtySum, paginatedRows]
    );

    const selectAllRows = useCallback(
        (isSelected) => {
            let rowIds = [];
            rows.forEach((row, i) => {
                if (row.CHILDREN) {
                    rowIds = rowIds.concat(row.CHILDREN.map((child) => `${id}_${child.ID}`));
                    const totalSum = row.CHILDREN.map((child) => child['QTY']).reduce((acc, current) => acc + current);
                    setQtySum((currQtySum) => ({
                        ...currQtySum,
                        [row.id]: isSelected ? totalSum : 0,
                    }));
                }
                onSelect(isSelected, id, row.id, rowIds, i === rows.length - 1);
                rowIds = [];
            });
        },
        [id, onSelect, rows]
    );

    const initSelectAllRows = () => {
        let rowIds = [];
        let currentSum = {};
        let selections = {};
        if (id === 'mxAsis' || id === 'opsAsis') {
            rows.forEach((row) => {
                const childRows = row.CHILDREN;
                if (childRows) {
                    rowIds = rowIds.concat(
                        childRows.map((child) => child.id).filter((child) => !exclusions.includes(child))
                    );
                    childRows.forEach((child) => {
                        if (!exclusions.includes(child.id)) {
                            currentSum[row.id] = currentSum[row.id] ? currentSum[row.id] + child['QTY'] : child['QTY'];
                        } else {
                            currentSum[row.id] = currentSum[row.id] ? currentSum[row.id] + 0 : 0;
                        }
                    });
                }
                selections[row.id] = rowIds;
                rowIds = [];
            });
        } else {
            rows.forEach((row) => {
                if (row.CHILDREN) {
                    rowIds = rowIds.concat(row.CHILDREN.map((child) => `${id}_${child.ID}`));
                    const totalSum = row.CHILDREN.map((child) => child['QTY']).reduce((acc, current) => acc + current);
                    currentSum[row.id] = totalSum;
                }
                selections[row.id] = rowIds;
                rowIds = [];
            });
        }

        setQtySum(currentSum);
        onInit(id, selections);
    };

    useSelectAllRows({
        isLoading,
        rowsRef,
        rows,
        selectAllRows: initSelectAllRows,
    });

    useInitExclusions({
        id,
        exclusions,
        exclusionsRef,
        selectAllRows: initSelectAllRows,
    });

    const totalQty = () => {
        let sum = 0;
        sum = Object.values(qtySum).reduce((acc, row) => acc + row, sum);
        return sum;
    };

    const allChecked = () => {
        if (rows.length === 0) return false;
        let allRows = {};
        rows.forEach((row) => {
            allRows[row.id] = row.CHILDREN.length;
        });
        for (const key in selectedIds) {
            if (selectedIds[key].length !== allRows[key]) {
                return false;
            }
        }
        return true;
    };

    return (
        <div>
            <div className={`equations-table ${className}`} data-testid={`datatable-${id}`}>
                <HeaderContent title={title} id={id} />
                <DataTable rows={paginatedRows} headers={headers} isSortable id={`datatable-${id}`}>
                    {({ rows, headers, getHeaderProps, getRowProps, getSelectionProps, getTableProps }) => (
                        <Table {...getTableProps()} className="sticky-table-header">
                            <TableHeaderContent
                                headers={headers}
                                getHeaderProps={getHeaderProps}
                                getSelectionProps={getSelectionProps}
                                disabled={disabled}
                                allChecked={allChecked()}
                                onSelectAllRows={selectAllRows}
                            />
                            {isLoading ? (
                                <TableLoading headers={headers} />
                            ) : (
                                <TableBody>
                                    {rows.map((row, rowIdx) => {
                                        const childRows = getChildRows({
                                            rowId: row.id,
                                        });
                                        const isMissing = getCellValue({
                                            rowId: row.id,
                                            isParent: true,
                                            header: 'MISSING',
                                        });
                                        const isCarryover = getCellValue({
                                            rowId: row.id,
                                            isParent: true,
                                            header: 'CARRYOVER',
                                        });
                                        const isAdditional = getCellValue({
                                            rowId: row.id,
                                            isParent: true,
                                            header: 'ADDITIONAL',
                                        });
                                        return (
                                            <React.Fragment key={row.id}>
                                                <TableExpandRow
                                                    {...getRowProps({ row })}
                                                    data-testid="equations-table-parent-row"
                                                >
                                                    <TableSelectRow
                                                        className={'table-cell-checkbox'}
                                                        ariaLabel="Select parent row for calculation"
                                                        id={row.id}
                                                        row={row}
                                                        disabled={disabled}
                                                        checked={
                                                            selectedIds[row.id]?.length === childRows.length ?? false
                                                        }
                                                        onSelect={(e) => {
                                                            selectAllChildRows({
                                                                isSelected: e.target.checked,
                                                                rowId: row.id,
                                                                tableId: id,
                                                            });
                                                        }}
                                                        name={`select-${row.id}`}
                                                    />
                                                    {row.cells.map((cell) => {
                                                        const col = cell.info.header;
                                                        return renderCell({
                                                            tableId: id,
                                                            cellId: cell.id,
                                                            col,
                                                            row,
                                                            isParentRow: true,
                                                            isMissing,
                                                            isCarryover,
                                                            isAdditional,
                                                            value: getCellValue({
                                                                rowId: row.id,
                                                                isParent: true,
                                                                header: col,
                                                            }),
                                                        });
                                                    })}
                                                </TableExpandRow>
                                                <TableExpandedRow colSpan={headers.length + 2}>
                                                    <Table {...getTableProps()} className="children-table">
                                                        <TableBody>
                                                            {childRows.map((columns, crIdx) => {
                                                                const columnsArr = [
                                                                    ['checkbox', ''],
                                                                    ...Object.entries(columns),
                                                                ];
                                                                return (
                                                                    <TableRow
                                                                        data-testid="equations-table-child-row"
                                                                        key={crIdx}
                                                                    >
                                                                        <TableExpandHeader />
                                                                        {columnsArr.map((col) => {
                                                                            return renderCell({
                                                                                cellId: `${col[0]}-${crIdx}`,
                                                                                tableId: id,
                                                                                col: col[0],
                                                                                row,
                                                                                value: col[1],
                                                                                selectedIds,
                                                                                childRowId: `${id}_${columns['ID']}`,
                                                                                parentId: columns['parentId'],
                                                                                qty: columns['QTY'],
                                                                                isParentRow: false,
                                                                                isMissing: columns['MISSING'],
                                                                                isCarryover: columns['CARRYOVER'],
                                                                                isAdditional: columns['ADDITIONAL'],
                                                                                onSelect,
                                                                            });
                                                                        })}
                                                                    </TableRow>
                                                                );
                                                            })}
                                                        </TableBody>
                                                    </Table>
                                                </TableExpandedRow>
                                            </React.Fragment>
                                        );
                                    })}
                                </TableBody>
                            )}
                        </Table>
                    )}
                </DataTable>
                <TablePagination
                    id={id}
                    page={page}
                    pageSize={pageSize}
                    totalItems={rows.length}
                    onPageSizeChange={onPageSizeChange}
                />
                <div className="table-footer">
                    {!isLoading && (
                        <>
                            <h4>
                                Total:&nbsp;
                                <strong>{totalQty()}</strong>
                                &nbsp;/&nbsp;
                                <strong>
                                    {rows.reduce((prev, curr) => {
                                        return prev + parseInt(curr.PARENT.QTY);
                                    }, 0)}
                                </strong>
                                &nbsp; (Baseline Total)
                            </h4>
                            {id === 'mxAsis' || id === 'opsAsis' ? (
                                <Button
                                    kind="tertiary"
                                    size="md"
                                    disabled={disabled}
                                    onClick={() => setCalculateTobeModalOpen(true)}
                                >
                                    Calculate TOBE
                                </Button>
                            ) : (
                                <></>
                            )}
                        </>
                    )}
                    {addRowButton && (
                        <div className="add-row-button">
                            <Button
                                kind="tertiary"
                                size="md"
                                renderIcon={Add}
                                iconDescription="plus sign"
                                onClick={() => onModalChange(true)}
                            >
                                Add Row
                            </Button>
                        </div>
                    )}
                </div>
            </div>
            {calculateTobeModalOpen && (
                <CalculateTobeModal
                    open={calculateTobeModalOpen}
                    onClose={() => setCalculateTobeModalOpen(false)}
                    onSubmit={calculateTOBE}
                />
            )}
        </div>
    );
};

EquationsTable.propTypes = {
    id: PropTypes.string,
    title: PropTypes.node,
    headers: PropTypes.arrayOf(
        PropTypes.shape({
            header: PropTypes.string,
            key: PropTypes.string.isRequired,
        })
    ),
    rows: PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool,
    addRowButton: PropTypes.func,
    onModalChange: PropTypes.func,
    content: PropTypes.string,
    page: PropTypes.number,
    pageSize: PropTypes.number,
    selectedIds: PropTypes.object,
    exclusions: PropTypes.arrayOf(PropTypes.string),
    onContentSwitch: PropTypes.func,
    onPageSizeChange: PropTypes.func,
    onSelect: PropTypes.func,
    onInit: PropTypes.func,
    disabled: PropTypes.bool,
};
