import React from 'react';
import { UnorderedList, ListItem, ToastNotification } from '@carbon/react';

export const FailedScenariosList = ({ scenarios, onClose }) => {
    return (
        <div className="error_notification--container">
            {scenarios.length > 0 ? (
                <ToastNotification
                    aria-label="closes notification"
                    kind="error"
                    onClose={onClose}
                    onCloseButtonClick={onClose}
                    statusIconDescription="Error notification"
                    timeout={40000}
                    title="Error"
                    subtitle="Could not approve the following scenarios:"
                >
                    <div className="failed-scenarios-list">
                        <UnorderedList>
                            {scenarios.map((scenario) => (
                                <ListItem key={scenario.id}>{scenario.scenario_name}</ListItem>
                            ))}
                        </UnorderedList>
                        <div className="mt-3">
                            <p className="h6">Please review the scenario's calculations.</p>
                        </div>
                    </div>
                </ToastNotification>
            ) : null}
        </div>
    );
};
