import React from 'react';
import {
    DataTable,
    TableContainer,
    TableToolbar,
    TableToolbarContent,
    TableToolbarSearch,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell,
    SkeletonText,
} from '@carbon/react';

const headers = [
    // { header: 'id', key: 'SCENARIO_ID' },
    { header: 'Scenario Name', key: 'SCENARIO_NAME' },
    { header: 'User', key: 'USER' },
    { header: 'MP Type', key: 'MP_TYPE' },
    { header: 'SC Type', key: 'SC_TYPE' },
    { header: 'Issue', key: 'ISSUE' },
    { header: '', key: 'action' },
];

export const ScenarioDatatable = ({ isLoading, type, scenarios }) => {
    return (
        <DataTable rows={scenarios} headers={headers} isSortable>
            {({
                rows,
                headers,
                getHeaderProps,
                getRowProps,
                getToolbarProps,
                onInputChange,
                getTableProps,
                getTableContainerProps,
            }) => {
                return (
                    <TableContainer title={`${type} Scenarios`} {...getTableContainerProps()}>
                        <div className="publish-table-description">
                            <p className="me-4">Valid and invalid scenarios</p>
                            <TableToolbar {...getToolbarProps()}>
                                <TableToolbarContent>
                                    <TableToolbarSearch onChange={onInputChange} />
                                </TableToolbarContent>
                            </TableToolbar>
                        </div>
                        <Table
                            {...getTableProps()}
                            className="sticky-table-header"
                            aria-label="Invalid and valid scenarios"
                        >
                            <TableHead>
                                <TableRow>
                                    {headers.map((header, i) => (
                                        <TableHeader
                                            key={i}
                                            isSortable
                                            {...getHeaderProps({
                                                header,
                                            })}
                                        >
                                            {header.header}
                                        </TableHeader>
                                    ))}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {isLoading ? (
                                    <TableRow>
                                        {headers.map((cell, cellIndex) => (
                                            <TableCell key={cellIndex}>
                                                <SkeletonText />
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ) : null}
                                {rows.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan={headers.length} style={{ textAlign: 'center' }}>
                                            <strong>No Invalid Scenarios</strong>
                                        </TableCell>
                                    </TableRow>
                                ) : null}
                                {rows.map((row, index) => (
                                    <TableRow
                                        key={row.id}
                                        {...getRowProps({
                                            row,
                                        })}
                                    >
                                        {row.cells.map((cell, cellIndex) => (
                                            <TableCell key={cell.id}>
                                                <strong>{cell.value}</strong>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                );
            }}
        </DataTable>
    );
};
