import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useAtom, useSetAtom } from 'jotai';
import { Add } from '@carbon/react/icons';
import { Button, InlineNotification } from '@carbon/react';

import { useFocusAtom, useIsMounted } from '../../../../hooks';
import { EquationsTable } from '../opsMx/EquationsTable';
import { OpsEquations } from '../../modals/opsEquations/OpsEquations';
import {
    insertOpsTooltipsAtom,
    opsAsisPaginationAtom,
    opsAsisRowsAtom,
    opsAsisSelectionsAtom,
    opsTobePaginationAtom,
    opsTobeRowsAtom,
    opsTooltipsAtom,
    opsSelectedDropdownDataAtom,
} from './OpsAtoms';
import { SelectedFilterTags } from '../opsMx/SelectedFilterTags';
import { gsFiltersAtom } from '../generalSettings/GeneralSettingsAtoms';
import { selectedOgPecsAtom } from '../unitDetails/UnitDetailsAtoms';
import { opsHeaders } from './OpsHeaders';

import { isApprovedScenarioAtom, scenarioAtom } from '../../../../hooks/wizardAtoms';
import { useSaveScenario } from '../../../../hooks/useSaveScenario';
import { handleRowsSelected } from '../opsMx/helpers/handleRowsSelected';
import { getRowExclusions } from '../opsMx/helpers/getRowExclusions';
import { fetchAircrewAsis, fetchAircrewTobe } from '../../aircrewApiCalls';
import { saveAllExclusions } from '../opsMx/helpers/useExclusions';
import { newRowsToExclude } from '../opsMx/helpers/newRowsToExclude';
import { FacMsiApiDropdowns } from '../opsMx/FacMsiApiDropdowns';
import { Banner } from '../opsMx/Banner';

export const Ops = () => {
    const [gsFilters] = useAtom(gsFiltersAtom);
    const [selectedOgPecs] = useAtom(selectedOgPecsAtom);
    const [asisRows, setAsisRows] = useAtom(opsAsisRowsAtom);
    const [tobeRows, setTobeRows] = useAtom(opsTobeRowsAtom);
    const [scenario_id] = useFocusAtom(scenarioAtom, 'scenario_id');
    const [isLoading, setIsLoading] = useState(false);
    const [isTobeLoading, setIsTobeLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [opsAsisPagination, setOpsAsisPagination] = useAtom(opsAsisPaginationAtom);
    const [opsTobePagination, setOpsTobePagination] = useAtom(opsTobePaginationAtom);
    const [asisRowsSelected, setAsisRowsSelected] = useAtom(opsAsisSelectionsAtom);
    const [isApprovedScenario] = useAtom(isApprovedScenarioAtom);
    const [tobeRowsSelected, setTobeRowsSelected] = useState({});
    const [exclusions, setExclusions] = useState([]);
    const isMounted = useIsMounted();

    const [opsTooltips] = useAtom(opsTooltipsAtom);

    const insertOpsTooltips = useSetAtom(insertOpsTooltipsAtom);
    const [asisIsMissing, setAsisIsMissing] = useState(false);
    const [tobeIsMissing, setTobeIsMissing] = useState(false);

    const [dropdownData, setDropdownData] = useState({ API: [], FAC: [], MSI: [] });
    const [selectedDropdownData, setSelectedDropdownData] = useAtom(opsSelectedDropdownDataAtom);

    const { isSaving } = useSaveScenario();

    const hasGeneralSettings = useMemo(() => {
        const { MDS, ILC, CMD } = gsFilters;
        return MDS.length > 0 && ILC.length > 0 && CMD.length > 0;
    }, [gsFilters]);

    const fetchASISData = useCallback(async () => {
        if (hasGeneralSettings) {
            setIsLoading(true);
            const { data, dropdowns, tooltips, isMissing } = await fetchAircrewAsis(scenario_id);
            if (!isMounted()) {
                return;
            }
            insertOpsTooltips(tooltips);
            setAsisIsMissing(isMissing);
            const rowExclusions = getRowExclusions(data);
            const mappedData = {
                API: dropdowns.API.filter((item) => item.CHECKED),
                FAC: dropdowns.FAC.filter((item) => item.CHECKED),
                MSI: dropdowns.MSI,
            };
            setAsisRows(data);
            setExclusions(rowExclusions);
            setDropdownData(dropdowns);
            setSelectedDropdownData(mappedData);
            setIsLoading(false);
            setOpsAsisPagination({ page: 1, pageSize: 10 });
        } else {
            setAsisRows([]);
        }
    }, [
        hasGeneralSettings,
        insertOpsTooltips,
        scenario_id,
        setAsisRows,
        setSelectedDropdownData,
        setOpsAsisPagination,
        isMounted,
    ]);

    const fetchTOBEData = useCallback(async () => {
        if (hasGeneralSettings) {
            // clear the state before fetching TOBE data
            setIsTobeLoading(true);
            const { data, tooltips, isMissing } = await fetchAircrewTobe(scenario_id);
            if (!isMounted()) {
                return;
            }
            insertOpsTooltips(tooltips);
            setTobeIsMissing(isMissing);
            setTobeRows(data);
            setIsTobeLoading(false);
            setOpsTobePagination({ page: 1, pageSize: 10 });
        } else {
            setTobeRows([]);
        }
    }, [hasGeneralSettings, insertOpsTooltips, scenario_id, setTobeRows, setOpsTobePagination, isMounted]);

    useEffect(() => {
        if (isSaving || scenario_id === -1) return;
        fetchASISData();
        fetchTOBEData();
    }, [isSaving, scenario_id, fetchASISData, fetchTOBEData]);

    const onEdit = () => {
        setModalOpen(true);
    };

    const ASISTitle = (
        <div className="d-flex justify-content-between align-items-center">
            <p>
                <strong>ASIS</strong> - Current Requirements Based on MPES/UMD
            </p>
            {tobeRows.length === 0 ? (
                <Button
                    kind="tertiary"
                    size="md"
                    renderIcon={Add}
                    iconDescription="plus sign"
                    onClick={() => {
                        // setShowAddModelDialog(true);
                        setModalOpen(true);
                    }}
                >
                    Add Model
                </Button>
            ) : null}
        </div>
    );
    const TOBETitle = (
        <div className="d-flex justify-content-between align-items-center">
            <p>
                <strong>TOBE</strong> - Calculated Requirements Based on PAI, Aircrew Ratio and Complement
            </p>
            {tobeRows.length > 0 && (
                <Button kind="tertiary" size="md" onClick={onEdit} data-testid="select-model-button">
                    Select Model
                </Button>
            )}
        </div>
    );

    const onSubmit = async () => {
        try {
            const { data } = await fetchAircrewTobe(scenario_id);
            if (!isMounted()) {
                return;
            }
            setIsTobeLoading(true);
            setTobeRows(data);
            // wait for api update for child parent relationship
            // only used to save data in session
            setModalOpen(false);
        } catch (error) {
            console.error(error);
        } finally {
            setIsTobeLoading(false);
        }
    };

    const handleSelection = async (isSelected, tableId, parentId, cellId) => {
        if (tableId === 'opsAsis') {
            handleRowsSelected({
                isSelected,
                parentId,
                cellId,
                setRowsSelected: setAsisRowsSelected,
            });
        } else {
            handleRowsSelected({
                isSelected,
                parentId,
                cellId,
                setRowsSelected: setTobeRowsSelected,
            });
        }
    };

    const handleInitSelections = (tableId, selections) => {
        tableId === 'opsAsis' ? setAsisRowsSelected(selections) : setTobeRowsSelected(selections);
    };

    const calculateTOBE = async () => {
        const { success } = await saveAllExclusions({
            exclusionData: newRowsToExclude({ rows: asisRows, rowsSelected: asisRowsSelected, scenario_id }),
            mpType: 'AIRCREW',
        });
        if (success) {
            fetchASISData();
            fetchTOBEData();
        }
    };

    if (selectedOgPecs.length === 0) {
        return (
            <div className="inline-notification-wrapper" data-testid="ops-missing-pec-wrapper">
                <InlineNotification
                    title="Missing OG PEC Selection"
                    subtitle="Please make a selection for OG PEC"
                    hideCloseButton
                    kind="error"
                />
            </div>
        );
    }

    return (
        <div className="tab-content">
            {asisIsMissing || tobeIsMissing ? <Banner type="missing" /> : null}
            <div className="header-content">
                <SelectedFilterTags />
                <FacMsiApiDropdowns
                    disabled={isApprovedScenario}
                    dropdownItems={dropdownData}
                    selectedFilters={selectedDropdownData}
                    setSelectedFilters={setSelectedDropdownData}
                    mpType="AIRCREW"
                    isLoading={isLoading}
                    setAsisRows={setAsisRows}
                    setExclusions={setExclusions}
                    setIsLoading={setIsLoading}
                />
            </div>
            <EquationsTable
                id="opsAsis"
                headers={opsHeaders}
                rows={asisRows}
                isLoading={isLoading}
                disabled={isApprovedScenario}
                title={ASISTitle}
                page={opsAsisPagination.page}
                pageSize={opsAsisPagination.pageSize}
                onPageSizeChange={setOpsAsisPagination}
                selectedIds={asisRowsSelected}
                exclusions={exclusions}
                onSelect={handleSelection}
                onInit={handleInitSelections}
                tooltips={opsTooltips}
                calculateTOBE={calculateTOBE}
            />
            <EquationsTable
                id="opsTobe"
                className="m-0"
                headers={opsHeaders}
                rows={tobeRows}
                isLoading={isTobeLoading}
                disabled={isApprovedScenario}
                title={TOBETitle}
                // addRowButton={true}
                onModalChange={setModalOpen}
                page={opsTobePagination.page}
                pageSize={opsTobePagination.pageSize}
                onPageSizeChange={setOpsTobePagination}
                selectedIds={tobeRowsSelected}
                onSelect={handleSelection}
                onInit={handleInitSelections}
                tooltips={opsTooltips}
            />
            <OpsEquations
                open={modalOpen}
                onClose={() => {
                    setModalOpen(false);
                }}
                onSubmit={onSubmit}
            />
        </div>
    );
};
